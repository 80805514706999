var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.formattedAlerts,"items-per-page":_vm.itemsPerPage,"item-class":_vm.alertUnseenStyle,"loading":_vm.loading,"footer-props":{
    itemsPerPageOptions: _vm.itemPerPageOptions,
  }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(("" + (item.formatted.date))))])]}},{key:"item.key",fn:function(ref){
  var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.seen ? '' : 'secondary',"outlined":item.seen ? true : false}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-"+_vm._s(_vm.icons[item.kind]))]),_c('v-divider',{attrs:{"vertical":""}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.key))])],1)]}},{key:"item.triggered_value",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.formatted.triggered_value))]),(item.triggered_value_threshold)?_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0, 0, 0, 0.9)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Risque max accepté :")]),_vm._v(" "+_vm._s(item.formatted.triggered_value_threshold)+" ")]):_vm._e()]}},{key:"item.triggered_financial_risk",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.formatted.triggered_financial_risk))])]}},{key:"item.triggered_duration",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.triggered_duration)+" "+_vm._s(_vm.$t("day")))])]}},{key:"item.feedback",fn:function(ref){
  var item = ref.item;
return [_c('Relevance',{attrs:{"alertData":item}})]}},{key:"item.analysis",fn:function(ref){
  var item = ref.item;
return [_c('Analysis',{attrs:{"alertData":item}})]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ path: '/alerts/' + item.id, params: { alertId: item.id } }}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-magnify")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }