<template>
  <v-data-table
    :headers="headers"
    :items="formattedAlerts"
    :items-per-page="itemsPerPage"
    :item-class="alertUnseenStyle"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: itemPerPageOptions,
    }"
  >
    <template v-slot:[`item.date`]="{ item }">
      <span>{{ `${item.formatted.date}` }}</span>
    </template>

    <template v-slot:[`item.key`]="{ item }">
      <v-chip
        :color="item.seen ? '' : 'secondary'"
        :outlined="item.seen ? true : false"
      >
        <v-icon left>mdi-{{ icons[item.kind] }}</v-icon>
        <v-divider vertical></v-divider>
        <span class="ml-2">{{ item.key }}</span>
      </v-chip>
    </template>

    <template v-slot:[`item.triggered_value`]="{ item }">
      <span>{{ item.formatted.triggered_value }}</span>
      <v-tooltip v-if="item.triggered_value_threshold" bottom color="rgba(0, 0, 0, 0.9)">
        <template v-slot:activator="{ on, attrs }">
          <v-icon right v-bind="attrs" v-on="on"> mdi-information </v-icon>
        </template>
        <span>Risque max accepté :</span>
        {{ item.formatted.triggered_value_threshold }}
      </v-tooltip>
    </template>

    <template v-slot:[`item.triggered_financial_risk`]="{ item }">
      <span>{{ item.formatted.triggered_financial_risk }}</span>
    </template>

    <template v-slot:[`item.triggered_duration`]="{ item }">
      <span>{{ item.triggered_duration }} {{ $t("day") }}</span>
    </template>

    <template v-slot:[`item.feedback`]="{ item }">
      <Relevance :alertData="item" />
    </template>

    <template v-slot:[`item.analysis`]="{ item }">
      <Analysis :alertData="item" />
    </template>

    <!-- <template v-slot:[`item.comment_count`]="{ item }">
      <Activity :alertData="item" />
    </template> -->

    <template v-slot:[`item.actions`]="{ item }">
      <router-link
        :to="{ path: '/alerts/' + item.id, params: { alertId: item.id } }"
        style="text-decoration: none"
      >
        <v-icon color="black">mdi-magnify</v-icon>
      </router-link>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
import { formatAlerts } from "@/services/alert.js";

export default {
  props: {
    alerts: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    rule: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    itemsPerPage: {
      type: Number,
    },
    light: {
      type: Boolean
    }
  },
  components: {
    Relevance: () => import("@/components/Alerts/Relevance.vue"),
    Analysis: () => import("@/components/Alerts/Analysis.vue"),
    // Activity: () => import("@/components/Alerts/Activity.vue"),
  },
  methods: {
    alertUnseenStyle: function (item) {
      return item.seen ? null : "unseen";
    },
  },
  computed: {
    itemPerPageOptions: function () {
      return this.light ? [3] : [15, 50, -1];
    },
    ...mapState({
      formattedAlerts: function (state) {
        return formatAlerts(this.alerts, {
          queries: state.rules.rulesNames,
        });
      },
      icons: (state) => state.alerts.alertsIcons,
    }),
  },
};
</script>
